import { useContext, useEffect, useState } from "react";
import SelectComp from "../../Components/Select";
import "./style.css";
import { FileUploader } from "react-drag-drop-files";
import SelectMultiply from "../../Components/SelectMultiply";
import {
  deleteBlock,
  getBlocks,
  getTovar,
  getUserData,
  saveBlocks,
  updateBlock,
} from "../../Api";
import { User } from "../../Contexts/User";
import Notification from "../../Components/Notification";
import notification from "../../Components/Notification";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { Store } from "react-notifications-component";
import { Fade } from "react-reveal";
const arr = ["Товары", "Акции", "Слайдер", "Кастомный блок"];
const fileTypes = ["JPG", "PNG", "GIF", "WEBP", "WEBM", "MP4"];
export default function MainPage() {
  const [mainPageData, setMainPageDate] = useState([]);
  const [select, setSelect] = useState("Выберите элемент");
  const [user, setUser] = useContext(User);
  const [videos, setVideos] = useState(["", ""]);
  const [products, setProducts] = useState([]);
  const [currentProducts, setCurrentProducts] = useState([]);
  const [begin, setBegin] = useState(false);
  const [customHtml, setCustomHtml] = useState("<div></div>");
  const [currentChange, setCurrentChange] = useState(null);
  const [updateHtml, setUpdateHtml] = useState("");
  const [openList, setOpenList] = useState(false);
  useEffect(() => {
    if (currentChange && currentChange.Type == "custom") {
      setUpdateHtml(currentChange.BlockValue);
    }
  }, [currentChange]);
  function Not(title, text) {
    Store.addNotification({
      title: title,
      message: text,
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__bounceIn"],
      animationOut: ["animate__animated", "animate__backOutUp"],
      dismiss: {
        duration: 4000,
        onScreen: true,
      },
    });
  }
  useEffect(() => {
    setCurrentProducts([]);
    setBegin(false);
    setCustomHtml("<div></div>");
  }, [select]);
  useEffect(() => {
    getBlocks().then((blocks) => {
      setMainPageDate(blocks);
    });
    getTovar().then((data) => {
      setProducts(data);
    });
  }, []);
  console.log(mainPageData);
  return (
    <div className="MainPageEditor container">
      <div className="mainEditBody">
        <div className="mainEditBodyLeft">
          {mainPageData &&
            mainPageData.length > 0 &&
            products &&
            products.length > 0 &&
            mainPageData.map((em) => {
              if (em.Type == "products") {
                let blocks = JSON.parse(em.BlockValue).map((id) => {
                  return products.find((elem) => elem.ID == id);
                });
                return (
                  <div
                    style={
                      currentChange && currentChange.ID == em.ID
                        ? { border: "3px solid #3e95ff" }
                        : {}
                    }
                    onClick={() =>
                      deleteBlock(em.ID).then((status) => {
                        if (status == 200) {
                          getBlocks().then((data) => {
                            Not("Редактирование блоков", "Блок успешно удален");
                            setMainPageDate(data);
                          });
                        }
                      })
                    }
                  >
                    <div className="prodType">
                      {blocks &&
                        blocks.length > 0 &&
                        blocks.map((block) => {
                          let imgs = block.Photo.replaceAll('"', "")
                            .trim()
                            .split(" ")[0];
                          return (
                            <div
                              style={{
                                width:
                                  100 / (user ? user.ColumnT : 4) - 2 + "%",
                              }}
                            >
                              <img src={imgs} alt="" />
                              <p> {block.Title}</p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              } else if (em.Type == "custom") {
                return (
                  <div
                    style={
                      currentChange && currentChange.ID == em.ID
                        ? { border: "3px solid #3e95ff" }
                        : {}
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setCurrentChange(em);
                    }}
                    dangerouslySetInnerHTML={{ __html: em.BlockValue }}
                  ></div>
                );
              }
            })}
        </div>
        <div className="mainEditBodyRight">
          <h1
            onClick={() => {
              setCurrentChange(null);
            }}
          >
            <span style={!currentChange ? { color: "#3e95ff" } : {}}>
              Добавить
            </span>{" "}
            <span style={currentChange ? { color: "#3e95ff" } : {}}>
              / Изменить
            </span>
          </h1>
          {!currentChange && <h3>Добавляемый элемент</h3>}
          {!currentChange && (
            <SelectComp
              select={select}
              setSelect={setSelect}
              arr={arr}
            ></SelectComp>
          )}
          {select == arr[0] && !currentChange && (
            <div className="postProducts">
              <p>
                Выберите число элементов <br /> кратное{" "}
                <b>{user && user.ColumnT}</b>
              </p>
              {products.length > 0 && (
                <SelectMultiply
                  select={currentProducts}
                  setSelect={setCurrentProducts}
                  arr={products}
                ></SelectMultiply>
              )}
              <p className="errMsg">
                {user &&
                user.ColumnT &&
                currentProducts.length % user.ColumnT != 0
                  ? `Нужно добавить позиций*: ${
                      4 - (currentProducts.length % user.ColumnT)
                    }`
                  : ""}
              </p>
              <div className="beginAdd">
                <input
                  type="checkbox"
                  id="begin"
                  checked={begin}
                  onChange={() => setBegin(!begin)}
                />
                <label htmlFor="begin"> Добавить в начало</label>
              </div>
              <textarea
                name="cssprop"
                id=""
                placeholder="Код css(опционально)"
              ></textarea>
              <div
                className="saveChanges"
                onClick={() => {
                  let value = currentProducts.map((em) => {
                    return em.ID;
                  });
                  saveBlocks("products", JSON.stringify(value)).then(
                    (status) => {
                      if (status == 200) {
                        getBlocks().then((data) => {
                          setMainPageDate(data);
                          setCurrentProducts([]);
                          Store.addNotification({
                            title: `Редактирование главной`,
                            message: `Блок успешно добавлен"`,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: [
                              "animate__animated",
                              "animate__bounceIn",
                            ],
                            animationOut: [
                              "animate__animated",
                              "animate__backOutUp",
                            ],
                            dismiss: {
                              duration: 4000,
                              onScreen: true,
                            },
                          });
                        });
                      }
                    }
                  );
                }}
              >
                Добавить блок
              </div>
            </div>
          )}
          {select == arr[1] && !currentChange && (
            <div className="actRed">
              <div className="redEdit">
                <h4>Первая акция</h4>
                <FileUploader
                  className="lols"
                  label={"Загрузите или перетащите файл"}
                  name="file"
                  types={fileTypes}
                />
                <input type="text" placeholder="Введите id товара" />
                <video src={videos[0]} controls></video>
              </div>
              <div className="redEdit">
                <h4>Вторая акция</h4>
                <FileUploader
                  label={"Загрузите или перетащите файл"}
                  name="file"
                  types={fileTypes}
                />
                <input type="text" placeholder="Введите id товара" />
                <video src={videos[1]} controls></video>
              </div>
            </div>
          )}
          {select == arr[2] && <div></div>}
          {currentChange && currentChange.Type == "custom" && (
            <div>
              <div
                className="saveChange"
                style={{
                  textAlign: "center",
                }}
                onClick={() => {
                  deleteBlock(currentChange.ID).then((status) => {
                    if (status == 200) {
                      setCurrentChange(null);
                      getBlocks().then((data) => {
                        setMainPageDate(data);
                        Not("Редактирование главной", "Блок успешно удален");
                      });
                    }
                  });
                }}
              >
                Удалить блок
              </div>
            </div>
          )}
          {currentChange && currentChange.Type == "custom" && (
            <Fade bottom duration="500">
              <div className="ide">
                <CodeMirror
                  value={updateHtml}
                  style={{
                    overflowY: "scroll",
                  }}
                  height={!openList ? "30vh" : "90vh"}
                  extensions={[javascript({ jsx: true })]}
                  onChange={setUpdateHtml}
                />
                <div className="htmlButtons">
                  <div
                    className="fullScreen"
                    onClick={() => {
                      setOpenList(!openList);
                    }}
                  >
                    {!openList ? "На весь экран" : "Свернуть"}
                  </div>
                  <div
                    className="saveChange"
                    onClick={() => {
                      updateBlock(currentChange.ID, updateHtml).then(
                        (status) => {
                          if (status == 200) {
                            getBlocks().then((data) => {
                              setMainPageDate(data);
                              Not(
                                "Редактирование блоков",
                                "Блок успешно обновлен"
                              );
                              setCurrentChange(null);
                            });
                          }
                        }
                      );
                    }}
                  >
                    Сохранить
                  </div>
                </div>
              </div>
            </Fade>
          )}

          {select == arr[3] && !currentChange && (
            <div className="customHtml">
              {/*     <textarea
                name=""
                value={customHtml}
                onChange={(e) => setCustomHtml(e.target.value)}
                id=""
              ></textarea> */}
              <Fade bottom duration="500">
                <div className="ide">
                  <CodeMirror
                    value={customHtml}
                    style={{
                      maxHeight: "20vw",
                      overflowY: "scroll",
                      height: "20vw",
                    }}
                    height="20vw"
                    extensions={[javascript({ jsx: true })]}
                    onChange={setCustomHtml}
                  />
                </div>
              </Fade>

              <div
                className="saveChanges absol"
                onClick={() => {
                  saveBlocks("custom", customHtml).then((status) => {
                    if (status == 200) {
                      getBlocks().then((data) => {
                        setMainPageDate(data);
                        setSelect("Выберите элемент");
                        Store.addNotification({
                          title: `Редактирование главной`,
                          message: `Блок успешно добавлен"`,
                          type: "success",
                          insert: "top",
                          container: "top-right",
                          animationIn: [
                            "animate__animated",
                            "animate__bounceIn",
                          ],
                          animationOut: [
                            "animate__animated",
                            "animate__backOutUp",
                          ],
                          dismiss: {
                            duration: 4000,
                            onScreen: true,
                          },
                        });
                      });
                    }
                  });
                }}
              >
                Добавить блок
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
