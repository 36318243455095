import { useContext, useState } from "react";
import "./style.css";
import { getPages, savePage } from "../../Api";
import Notice from "../../Components/Notice/Notice";
import { AdminData } from "../../Contexts/AdminData";
import { Navigate } from "react-router-dom";
import { routes } from "../../routes";
export default function CreatePage() {
  const [form, setForm] = useState({
    url: "",
    name: "",
  });
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const [pages, setPages] = useContext(AdminData);
  return (
    <div className="createPage container">
      <div className="formContainer">
        <div className="blockInput">
          <p>Название страницы </p>
          <input
            type="text"
            name="url"
            value={form.url}
            onChange={handleChange}
          />
        </div>
        <div className="blockInput">
          <p>Введите URL страницы (например 'компания')</p>
          <input
            type="text"
            name="name"
            value={form.name}
            onChange={handleChange}
          />
        </div>
        <div
          className="saveButton"
          onClick={() => {
            form.url.length > 0 && form.name.length != 0
              ? savePage(form).then((status) => {
                  if (status == 200) {
                    getPages().then((data) => {
                      setPages({ ...pages, pages: data });
                      Notice(
                        "Добавление страницы",
                        "Страница успещно создана",
                        "success"
                      );
                      setForm({
                        url: "",
                        name: "",
                      });
                    });
                  }
                })
              : Notice("Ошибка", "Заполните поля", "danger");
          }}
        >
          Создать
        </div>
      </div>
    </div>
  );
}
