import "./style.css";
export default function SelectMultiply({ select, setSelect, arr }) {
  return (
    <div className="selectMain">
      <div className="selectVisible">Выбрано {select.length} элементов</div>
      <div className="selectHide">
        {arr.map((em) => {
          if (em != select) {
            const fin = select.find((elem) => elem.ID == em.ID);
            return (
              <div
                onClick={() =>
                  fin
                    ? setSelect((select) =>
                        select.filter((item) => item.ID !== em.ID)
                      )
                    : setSelect([...select, em])
                }
                className="selectMulElem"
              >
                <input checked={fin ? true : false} type="checkbox"></input>{" "}
                {em.Title}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}
