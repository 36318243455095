import { useEffect, useRef, useState } from "react";
import "./style.css";
import ava from "./ava.jpg";
import you from "./ava.png";
import { getChat, getUserByPhone, sendMessage } from "../../Api";
import EmojiPicker from "emoji-picker-react";
import { Fade } from "react-reveal";
export default function Chat() {
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");
  const [userData, setUserData] = useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("key");
  const chat = useRef(null);

  useEffect(() => {
    getUserByPhone(myParam).then((data) => {
      setUserData(data);
    });
  }, []);
  useEffect(() => {
    if (userData.length > 0) {
      getChat(userData[0].ID).then((data) => {
        setMessages(data);
      }, []);
    }
  }, [userData]);
  useEffect(() => {
    console.log("fff");
    const interval = setInterval(() => {
      getChat(userData[0].ID).then((data) => {
        setMessages(data);
      });
    }, 3000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    chat.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);
  return (
    <div className="container" style={{ paddingTop: "20px" }}>
      <h4 className="titleChat">Чат с пользователем {"User"}</h4>
      <div className="messages">
        {messages.length > 0 &&
          messages.map((elem, index) => {
            return (
              <div key={index}>
                {elem.Bot != "1" && (
                  <article>
                    <img src={ava} alt="" />
                    <p>user</p>
                  </article>
                )}
                {elem.Bot == "1" ? (
                  <article>
                    <img src={you} alt="" />
                    <p>Вы</p>
                  </article>
                ) : (
                  <p></p>
                )}
                <div style={elem.Bot == "1" ? { background: "#f0fbff" } : {}}>
                  {elem.textMessage}{" "}
                  <span>{elem.timeMessage.split(" ")[1]}</span>
                </div>
              </div>
            );
          })}
        <p ref={chat} />
      </div>
      <Fade bottom duration={500}>
        <div className="addMessageText">
          <input
            type="text"
            placeholder="Текст..."
            value={text}
            onChange={(e) => setText(e.target.value)}
            id="kek"
            autoComplete="off"
          />

          <div
            className="sendMessage"
            onClick={() =>
              text.length > 0 &&
              sendMessage(text).then(() => {
                getChat(userData[0].ID).then((data) => {
                  setMessages(data);
                }, []);
                setText("");
              })
            }
          >
            Отправить
          </div>
        </div>
      </Fade>
    </div>
  );
}
