import { useContext, useEffect, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { User } from "../../Contexts/User";
import "./style.css";
import { getUserData, updateCss } from "../../Api";
import Notice from "../../Components/Notice/Notice";
export default function CssPage() {
  const [css, setCss] = useState("<div><style></style></div>");
  const [user, setUser] = useContext(User);
  useEffect(() => {
    setCss(user.CSS);
  }, [user]);
  return (
    <div className="container cssMode">
      <div className="noTr">
        <CodeMirror
          value={css}
          style={{
            overflowY: "scroll",
          }}
          height="85vh"
          extensions={[javascript({ jsx: true })]}
          onChange={setCss}
        />
      </div>
      <div
        className="saveButton"
        onClick={() => {
          updateCss(css).then((status) => {
            if (status == 200) {
              getUserData().then((data) => {
                setUser(data);
                Notice("CSS", "Css обновлен", "success");
              });
            }
          });
        }}
      >
        Сохранить
      </div>
    </div>
  );
}
