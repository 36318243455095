import "./App.css";
import AdminLogin from "./adminPages/adminLogin.jsx";
import AdminDogovor from "./adminPages/adminDogovor";
import AdminMainPage from "./adminPages/Main/adminMainPage.jsx";
import AdminRegister from "./adminPages/adminRegister.jsx";
import { Routes, Route, useLocation } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import { routes } from "./routes";
import "react-notifications-component/dist/theme.css";
import Header from "./header/Header";
import AdminMainTable from "./adminPages/AdminMainTable/AdminMainTable";
import Navbar from "./navbar/Navbar";
import "./fonts/stylesheet.css";
import React, { useEffect } from "react";
import "animate.css";
import { Context } from "./Context.js";
import { useState } from "react";
import Actions from "./adminPages/Actions/Actions";
import Services from "./adminPages/Services";
import Contacts from "./adminPages/Contacts";
import Clients from "./adminPages/Clients";
import { AnimatePresence } from "framer-motion";
import Doctors from "./adminPages/Doctors";
import { Voice } from "./Voice";
import Objects from "./EstatePages/Objects";
import Orders from "./Magazine/Orders";
import Tovar from "./Magazine/Tovar";
import Chat from "./adminPages/Chat";
import MainShop from "./Magazine/MainShop";
import logo from "./imgs/img/logo.svg";
import ph from "./imgs/img/ph.svg";
import tg from "./imgs/img/tg.svg";
import wt from "./imgs/img/wt.svg";
import tim from "./imgs/img/tim.svg";
import Razdel from "./EstatePages/Razdel/index.jsx";
import EditPage from "./EditPages/EditPage.jsx";
import OrderPage from "./Magazine/Orders/OrderPage/OrderPage.jsx";
import ThemesPage from "./Showcase/Themes/index.jsx";
import Profile from "./VitrinaPages/Profile/index.jsx";
import { User } from "./Contexts/User.js";
import MainPage from "./Pages/MainPage/index.jsx";
import CreatePage from "./Pages/CreatePage/index.jsx";
import { AdminData } from "./Contexts/AdminData.js";
import { getPages } from "./Api.js";
import ChangePage from "./Pages/ChangePage/index.jsx";
import CssPage from "./Pages/CssPage/index.jsx";
function App() {
  const [popSend, isPopSend] = useState({
    popSend: false,
    profileSend: false,
    contacts: false,
    popMessage: false,
  });
  const [user, setUser] = useState(
    localStorage.user ? JSON.parse(localStorage.user) : []
  );
  const [adminData, setAdminData] = useState({
    pages: "",
  });
  useEffect(() => {
    if (user) {
      localStorage.user = JSON.stringify(user);
    }
  }, [user]);
  const location = useLocation();
  const [voise, setVoice] = useState("");
  useEffect(() => {
    getPages().then((data) => {
      setAdminData({ ...adminData, pages: data });
    });
  }, []);
  return (
    <AnimatePresence wait>
      <AdminData.Provider value={[adminData, setAdminData]}>
        <User.Provider value={[user, setUser]}>
          <Context.Provider value={[popSend, isPopSend]}>
            <Voice.Provider value={[voise, setVoice]}>
              <ReactNotifications />
              <div className="App">
                {location.pathname != routes.dogovor && (
                  <div className="flexibility" style={{ width: "100%" }}>
                    {location.pathname != routes.login &&
                      location.pathname != "/" &&
                      location.pathname != routes.register && <Navbar />}
                    <div
                      className="rightCont"
                      style={
                        location.pathname == routes.login ||
                        location.pathname == routes.register ||
                        location.pathname == "/"
                          ? { marginLeft: "0" }
                          : {}
                      }
                    >
                      {location.pathname != routes.login &&
                        location.pathname != "/" &&
                        location.pathname != routes.register && <Header />}

                      <Routes>
                        {/* Недвижимость */}
                        <Route path={routes.objects} element={<Objects />} />
                        <Route path={routes.razdel} element={<Razdel />} />
                        <Route path={routes.start} element={<AdminLogin />} />
                        <Route path={routes.login} element={<AdminLogin />} />
                        <Route path={routes.chat} element={<Chat />} />
                        <Route
                          path={routes.register}
                          element={<AdminRegister />}
                        />
                        <Route
                          path={routes.dogovor}
                          element={<AdminDogovor />}
                        />
                        <Route path={routes.cssPage} element={<CssPage />} />
                        <Route path={routes.actions} element={<Actions />} />
                        <Route path={routes.main} element={<AdminMainPage />} />
                        <Route
                          path={routes.orderDetails}
                          element={<OrderPage />}
                        />
                        <Route path={routes.clients} element={<Clients />} />
                        <Route
                          path={routes.MainPageShop}
                          element={<MainShop />}
                        />
                        <Route
                          path={routes.changePage}
                          element={<ChangePage />}
                        />
                        <Route path={routes.contacts} element={<Contacts />} />
                        <Route path={routes.mainEdit} element={<MainPage />} />
                        <Route path={routes.services} element={<Services />} />
                        <Route path={routes.edit} element={<EditPage />} />
                        <Route path={routes.orders} element={<Orders />} />
                        <Route path={routes.tovar} element={<Tovar />} />
                        <Route path={routes.themes} element={<ThemesPage />} />
                        <Route path={routes.profile} element={<Profile />} />
                        <Route
                          path={routes.createPage}
                          element={<CreatePage />}
                        />
                        <Route
                          path={routes.adminMainTable}
                          element={<AdminMainTable />}
                        />
                      </Routes>
                      {(location.pathname == routes.main ||
                        location.pathname.includes("orderDetails")) && (
                        <div className="showFooter">
                          <div className="showFooterBody">
                            <img src={logo} alt="" />
                            <p>Контакты</p>
                            <div className="addLink">
                              <p>Телефон:</p>
                              <a href="tel:+7 (925) 333 83-63">
                                +7 (925) 333 83-63
                                <img src={ph} alt="" />
                              </a>
                            </div>
                            <div className="addLink">
                              <p>Мессенджеры:</p>
                              <div>
                                <a
                                  href="https://t.me/internetvitrina"
                                  target="_blank"
                                >
                                  Telegramm <img src={tg} alt="" />
                                </a>
                                <a
                                  href="https://wa.me/79253338363"
                                  target="_blank"
                                >
                                  Whatsapp <img src={wt} alt="" />
                                </a>
                              </div>
                            </div>
                            <div className="addLink">
                              <p>Время работы:</p>
                              <a href="">
                                с 9:00 до 21:00, пн-вс <img src={tim} alt="" />
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Voice.Provider>
          </Context.Provider>
        </User.Provider>
      </AdminData.Provider>
    </AnimatePresence>
  );
}

export default App;
