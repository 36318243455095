import AnimatedPage from "../../animatedPage";
import "./style.css";

export default function Contacts() {
  return (
    <AnimatedPage>
      <div className="container">
        <div className="rekviziti">
          <p className="blue_rekvizit rekvizit_text">
            ИП Сосенко Михаил Васильевич
          </p>
          <p className="darkblue_rekvizit rekvizit_text">
            Адрес: 644109, Омская обл., г. Омск, ул. Молодова, д. 8 кв. 90
          </p>
          <p className="lightblue_rekvizit rekvizit_text">ИНН: 550617135927</p>
          <p className="darkblue_rekvizit rekvizit_text">
            ОГРНИП: 320554300060443
          </p>
          <p className="lightblue_rekvizit rekvizit_text">
            р/с: 40802810812500014353
          </p>
          <p className="darkblue_rekvizit rekvizit_text">
            Банк: ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ"
          </p>
          <p className="lightblue_rekvizit rekvizit_text">БИК: 044525999</p>
          <p className="darkblue_rekvizit rekvizit_text">
            К/с: 30101810845250000999
          </p>
          <p className="lightblue_rekvizit rekvizit_text">
            Телефон: +7 (913) 963 9712
          </p>
          <p className="darkblue_rekvizit rekvizit_text">
            WhatsApp/Telegram +7 (913) 963 9712
          </p>
          <p className="lightblue_rekvizit rekvizit_text">
            Эл.почта: msprojects@yandex.ru
          </p>
        </div>
      </div>
    </AnimatedPage>
  );
}
