import { useEffect, useState } from "react";
import { formatDate, getOrder, getOrdersUser } from "../../../Api";
import { OrderCard } from "./OrderCard";
import "./style.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Radial from "../../../GraphicsComp/Radial";
export default function OrderPage() {
  const searchString = new URLSearchParams(window.location.search);
  const [positions, setPositions] = useState();
  const id = searchString.get("key");
  const [userOrders, setUserOrders] = useState();
  const [order, setOrder] = useState();
  useEffect(() => {
    getOrder(id).then((data) => {
      setOrder(data[0]);
    });
  }, []);
  console.log(userOrders);
  useEffect(() => {
    if (order) {
      setPositions(JSON.parse(order.Positions));
      getOrdersUser(order.telephone).then((data) => {
        setUserOrders(
          data.map((obj) => {
            return {
              ...obj,
              timeOrder: formatDate(JSON.parse(obj.timeOrder)),
            };
          })
        );
      });
    }
  }, [order]);

  return (
    <div className="container orderPage">
      <div className="osnOrder">
        <div className="leftOrderPage">
          <h3>Заказ №{order && order.ID}</h3>
          <div className="orderTime">
            <span>Дата заказа: </span>
            {order && formatDate(JSON.parse(order.timeOrder))}
          </div>
          <div className="orderTime">
            <span>Время заказа: </span>
            {order && JSON.parse(order.timeOrder).substr(11, 8)}
          </div>
          <div className="orderTime">
            <span>Статус: </span>
            {order && order.StatusComp}
          </div>
          <div className="orderTime">
            <span>Сумма позиций: </span>
            {order && order.summ + "₽"}
          </div>
          <div className="orderTime">
            <span>Промокод: </span>
            Нет
          </div>
          <h4>Данные пользователя</h4>
          <div className="orderTime">
            <span>ФИО: </span>
            Паршенков Кирилл Романович
          </div>
          <div className="orderTime">
            <span>Телефон: </span>
            {order && order.telephone}
          </div>
          <div className="orderTime">
            <span>Email: </span>
            example@mail.ru
          </div>
          <div className="orderTime">
            <span>Адрес доставки: </span>
            г.Санкт Петербург, ул. Ломоносова 24, парадная 3, кв. 23
          </div>
        </div>
        <div className="positions">
          <h3>Позиции в заказе</h3>
          {positions &&
            positions.length > 0 &&
            positions.map((em) => {
              console.log();
              return <OrderCard id={em.id} len={em.length}></OrderCard>;
            })}
        </div>
        <div className="buttonsOrder">
          <h3>Статистика клиента</h3>
          <div className="personalGr">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                width={420}
                height={350}
                data={userOrders}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="summ" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="summ" stackId="a" fill="#0075FF" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="sendBtn">Написать сообщение</div>
          <div className="manage">Назначить менеджера</div>
        </div>
      </div>
    </div>
  );
}
