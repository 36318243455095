import { AreaChart, Area, ResponsiveContainer } from "recharts";
export default function CustomGr({ dataArr, color, fillColor, sizeX }) {
  return (
    <div className="customGr">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={sizeX}
          height={150}
          data={dataArr}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <Area type="monotone" dataKey="uv" stroke={color} fill={fillColor} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
