import { useState } from "react";

export default function ThemeEm({ elem, ids }) {
  const [value, setValue] = useState(elem.value);
  return (
    <div className="flexTheme">
      <p>{elem.name}</p>
      <input
        className={"themeIn" + ids}
        type="text"
        name={elem.name}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <div
        className="themEm"
        style={{
          background: elem.value,
        }}
      ></div>
    </div>
  );
}
