export default function EditPage() {
  const searchString = new URLSearchParams(window.location.search);

  const clickid = searchString.get("key");
  return (
    <div className="container">
      geag
      <frame src={clickid} />
    </div>
  );
}
