import "./style.css";
export default function SelectComp({ select, setSelect, arr }) {
  return (
    <div className="selectMain">
      <div className="selectVisible">{select}</div>
      <div className="selectHide">
        {arr.map((em) => {
          if (em != select)
            return <div onClick={() => setSelect(em)}>{em}</div>;
        })}
      </div>
    </div>
  );
}
