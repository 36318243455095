import { useEffect, useRef, useState } from "react";
import "./style.css";
import cloud from "../../imgs/cloud.svg";
import ok from "./ok.png";
import delete2 from "../../imgs/delete.svg";
import Notification from "../../Components/Notification";
import AnimatedPage from "../../animatedPage";
export default function Actions() {
  const [stocks, setStocks] = useState([]);
  const [notification, setNotification] = useState("");
  const [currentAction, setCurrentAction] = useState();
  const [file1, setFile1] = useState(false);
  const [file2, setFile2] = useState(false);
  const fileName = useRef(null);
  const fileName2 = useRef(null);
  function deleteMe(id) {
    let formData = new FormData();
    formData.append("ID", id);
    fetch("https://comeback-team.ru/deleteStock.php", {
      method: "POST",
      body: formData,
    }).then((response) => {
      if (response.status == 200) {
        getNotification("Акция удалена");
        getStock();
      }
    });
  }
  function getNotification(string) {
    setNotification(string);
    let div = document.getElementsByClassName("notification")[0];
    div.style = "top: 1vw";
    setTimeout(() => {
      div.style = "top: -5vw";
    }, 2000);
  }
  function onChange() {
    const data = new FormData();
    let path1 = "https://comeback-team.ru/";
    let path2 = "https://comeback-team.ru/";
    data.append("file", fileName.current.files[0]);
    fetch("https://comeback-team.ru/avatar.php", {
      method: "post",
      body: data,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        path1 = path1 + data;
        let formData = new FormData();
        formData.append("file", fileName2.current.files[0]);
        fetch("https://comeback-team.ru/avatar.php", {
          method: "post",
          body: formData,
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            let saveData = new FormData();
            path2 = path2 + data;
            saveData.append("path1", path1);
            saveData.append("path2", path2);
            saveData.append("ID", localStorage.id);
            fetch("https://comeback-team.ru/avatar2.php", {
              method: "post",
              body: saveData,
            }).then((response) => {
              if (response.status == 200) {
                setFile1(false);
                setFile2(false);
                document.getElementById("first").value = "";
                document.getElementById("second").value = "";
                getNotification("Акция добавлена");
                getStock();
              }
            });
          });
      });
  }
  function getStock() {
    let formData = new FormData();
    formData.append("ID", localStorage.id);
    fetch("https://comeback-team.ru/getStocks.php", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setStocks(data);
      });
  }
  useEffect(() => {
    getStock();
  }, []);
  return (
    <AnimatedPage>
      <div className="container">
        <div className="flexCont">
          <Notification text={notification} />
          <div className="actionsCont">
            {stocks &&
              stocks.length > 0 &&
              stocks.map((img, index) => (
                <div
                  key={index}
                  className="actions"
                  onClick={() => {
                    setCurrentAction(index);
                  }}
                >
                  <img src={img.imgList} alt="" />
                  {index == currentAction && (
                    <div className="panel">
                      <div
                        className="panEm"
                        onClick={() => deleteMe(img.idStock)}
                      >
                        <img src={delete2} alt="" />
                        <p>Удалить</p>
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div className="formActions">
            <p className="add_me">Добавить Отзыв</p>
            {localStorage.tel != "+7 (980) 800 8080" && (
              <div className="imgAdd">
                <p>
                  Загрузите первое <br /> изображение
                </p>
                <input
                  type="file"
                  onChange={() => {
                    setFile1(true);
                  }}
                  name="file"
                  ref={fileName}
                  id="first"
                />
                <img src={file1 ? ok : cloud} alt="" />
                <label htmlFor="first">Загрузить</label>
              </div>
            )}{" "}
            {localStorage.tel != "+7 (980) 800 8080" && (
              <div className="imgAdd">
                <p>
                  Загрузите второе <br /> изображение
                </p>
                <input
                  type="file"
                  onChange={() => {
                    setFile2(true);
                  }}
                  name="file"
                  ref={fileName2}
                  id="second"
                />
                <img src={file2 ? ok : cloud} alt="" />
                <label htmlFor="second">Загрузить</label>
              </div>
            )}
            <div
              className="button"
              style={
                file1 && file2 ? {} : { pointerEvents: "none", opacity: "0.6" }
              }
              onClick={onChange}
            >
              Сохранить
            </div>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
}
