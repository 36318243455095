import React, { useEffect, useState } from "react";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Январь",
    uv: 452100,
    pv: 22400,
    amt: 2400,
  },
  {
    name: "Февраль",
    uv: 305300,
    pv: 12398,
    amt: 2210,
  },
  {
    name: "Март",
    uv: 253012,
    pv: 92800,
    amt: 2290,
  },
  {
    name: "Апрель",
    uv: 278042,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "Май",
    uv: 189011,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "Июнь",
    uv: 239095,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "Июль",
    uv: 349004,
    pv: 4300,
    amt: 2100,
  },
];

const labels = ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль"];
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <p className="label">
          {`Выручка за ${label}:`} <b>{`${payload[0].value}`}</b> руб.
        </p>
        <p className="desc">123 продажи</p>
      </div>
    );
  }

  return null;
};
export default function Grafic() {
  return (
    <div className="mainGr">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={650}
          height={350}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Area type="monotone" dataKey="uv" stroke="#3E95FF" fill="#a7cefe" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
