import { useEffect, useState } from "react";
import { getCard } from "../../../Api";
import "./style.css";
export function OrderCard({ id, len }) {
  const [card, setCard] = useState();
  const [cardImg, setCardImg] = useState();
  useEffect(() => {
    getCard(id).then((data) => {
      setCard(data[0]);
    });
  }, []);
  console.log(len);
  useEffect(() => {
    card && setCardImg(card.Photo.split(" "));
  }, [card]);
  return (
    <div className="orderCard">
      <img src={cardImg && cardImg[0]} alt="" />
      <div className="orderCardTitle">
        <p>{card && card.Title}</p>
        <span>{card && card.Type}</span>
        <section>Количество: {len}</section>
      </div>
      <div className="orderCardPrice">
        {len} x {card && card.Price}₽
      </div>
    </div>
  );
}
