import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "Ирина",
    ["Продажи"]: 12,
    ["Прибыль ₽"]: 24050,
    ["Выручка ₽"]: 8600,
  },
  {
    name: "Артем",
    ["Продажи"]: 15,
    ["Прибыль ₽"]: 53598,
    ["Выручка ₽"]: 15210,
  },
  {
    name: "Мария",
    ["Продажи"]: 4,
    ["Прибыль ₽"]: 9800,
    ["Выручка ₽"]: 2290,
  },
];

export default function Radial() {
  return (
    <div className="personalGr">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={420}
          height={350}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="Сумма заказа" stackId="a" fill="#8884d8" />
          <Bar dataKey="Выручка ₽" stackId="a" fill="#82ca9d" />
          <Bar dataKey="Продажи" fill="#ffc658" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
